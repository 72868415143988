import { adaptV4Theme } from '@mui/material';
import { createTheme} from '@mui/material/styles';

import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import shadows, { customShadows } from './shadows';

const isLight = true;

const themeOptions = {
  palette: isLight
    ? { ...palette.light, mode: 'light' }
    : { ...palette.dark, mode: 'dark' },
  shape,
  typography,
  breakpoints,
  shadows: isLight ? shadows.light : shadows.dark,
  customShadows: isLight ? customShadows.light : customShadows.dark,
};

// const theme = createTheme({
//   typography: {
//     fontFamily: ["Open Sans", "sans-serif"].join(","),
//   },
// });

const theme = createTheme(adaptV4Theme(themeOptions));

export default theme;
